// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   venue -> option / Spielort
//   vorname -> text
//   nachname -> text
//   funktion -> text
//   foto -> asset
//   text -> markdown
//   body -> bloks
// end of automatic

import React from "react"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import { Helmet } from "react-helmet"
import * as moment from "moment"

import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"
import menuItems from "../../components/constants/menuItems"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"

moment.locale("de")

const PersonPage = ({ content, name, children, location }) => {
  let submenuItems = {}
  //   submenuItems.headline = content.title
  //   submenuItems.links = [
  //     { title: "Information", url: "#information" },
  //     { title: "Termine & Tickets", url: "#termine" },
  //   ]

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={content.venue}
      location={location}
    >
      <SEO title={name}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      <BlockWrapper blockPadding="pt-4 pb-0" block={{ _uid: "informationen" }}>
        <div className="flex flex-row flex-wrap -mx-grid">
          <div className="w-full md:w-1/3 px-grid">
            <Image className="mb-8" image={content.foto} />
          </div>
          <div className="w-full md:w-2/3 px-grid">
            <h1 className="block headline headline-h2">
              {content.vorname} {content.nachname}
            </h1>
            <span className="block subheadline subheadline-h2">
              {content.funktion}
            </span>
            <Richtext className="mt-8" text={content.text} />
          </div>
        </div>
      </BlockWrapper>
      {children}
    </Layout>
  )
}

export default PersonPage
