import React, { useRef, useEffect } from "react"

import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import menuItems from "../constants/menuItems"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const useVenuesReveal = () => {
  const refVenuesReveals = useRef([])
  refVenuesReveals.current = []

  const refVenuesRevealsCollection = (el) => {
    if (el && !refVenuesReveals.current.includes(el)) {
      refVenuesReveals.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refVenuesReveals.current, {
      autoAlpha: 0,
      duration: refVenuesReveals.current.length * 0.25,
      stagger: 0.2,
      ease: "power1.out",
      scrollTrigger: {
        trigger: refVenuesReveals.current[0],
        toggleActions: "play none none reverse",
        start: "start 75%",
        end: "bottom 25%",
      },
    })
  }, [])
  return [refVenuesRevealsCollection]
}

const VenuesGridBlock = ({ block }) => {
  const [refVenuesRevealsCollection] = useVenuesReveal()
  return (
    <BlockWrapper
      block={{
        component: "location_grid_block",
        headline: "Veranstaltungsorte",
      }}
      showHeadline={true}
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        {menuItems.links.map((item, index) => {
          return (
            <div
              ref={refVenuesRevealsCollection}
              className={` mt-8 w-1/2 md:w-1/3 px-grid theme-${item.venue}`}
              key={index}
            >
              <Link
                link={item.url}
                className="relative block overflow-hidden border-4 rounded-full border-primary"
              >
                <Image
                  image={item.image}
                  className="w-full h-auto"
                  aspectRatio="1by1"
                  /* fixedSize="300x300" */
                />
                <span className="absolute inset-0 flex items-center justify-center w-full h-full">
                  <span className="w-full text-base font-bold leading-relaxed text-center text-white bg-opacity-50 sm:py-1 sm:text-xl xl:text-2xl bg-primary">
                    {item.title}
                  </span>
                </span>
              </Link>
            </div>
          )
        })}
      </div>
    </BlockWrapper>
  )
}
export default VenuesGridBlock
