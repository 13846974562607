// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   tab-83f8a606-b809-4c8d-8391-4fa95f85855d -> tab / Details
//   tab-786f400f-4d2a-471f-9c2d-b89864135f8e -> tab / Termine
//   tab-0bac2463-b8c1-411b-a432-0ccff28515ef -> tab / Dauertermin
//   tab-cd635c84-3dae-479f-8f42-c05d7bf2a708 -> tab / SEO
//   cover -> asset / Plakat
//   description -> markdown / Lange Beschreibung
//   body -> bloks
//   duration -> text / Dauer
//   price -> markdown / Preis
//   homepage -> boolean / Kultur in Bad Vilbel Starteite
//   highlight -> boolean / Spielort Startseite
//   venue -> option / Spielort
//   kicker -> text / Dachzeile
//   title -> text / Titel
//   subheadline -> text / Unterzeile
//   image -> asset / Szenenbild
//   intro -> textarea / Teaser
//   category -> options / Kategorien
//   infos -> options
//   dates -> bloks
//   metadata -> custom
//   price_range -> text / Preisspanne
//   range_start -> datetime / Start
//   range_end -> datetime / Ende
//   range_text -> text / Zeitraum
// end of automatic

import React, { useRef, useEffect, useState, Fragment } from "react"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import { Helmet } from "react-helmet"
import { gsap } from "gsap"
import * as moment from "moment"
import IframeResizer from "iframe-resizer-react"

import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import HeroBlock from "gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapPin,
  faTag,
  faInfoSquare,
  faClock,
  faSpinner,
  faCalendarPen,
} from "@fortawesome/pro-solid-svg-icons"
import useModal from "gatsby-theme-yum-components/src/hooks/useModal"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"

import useStoryblokDatasource from "gatsby-theme-yum-components/src/hooks/useStoryblokDatasource"
import menuItems from "../../components/constants/menuItems"
import WidgetEmbed from "gatsby-theme-yum-components/src/components/technical/WidgetEmbed"

import ICalendarLink from "react-icalendar-link"

moment.locale("de")

// const useKenBurns = () => {
//   const imageRef = useRef(null)

//   useEffect(() => {
//     gsap.to(imageRef.current, {
//       scale: 1.2,
//       duration: 15,
//       ease: "power1.out",
//     })
//   }, [])
//   return { imageRef }
// }

const EventPage = ({ content, name, children, venue, location }) => {
  const [activeItem, setActiveItem] = useState({})
  const { storyblokDatasourceLookup } = useStoryblokDatasource()

  if (location === undefined && window !== undefined) {
    location = window.location
  }

  let submenuItems = {}
  submenuItems.headline = content.title
  submenuItems.links = [
    { title: "Information", url: "#information", type: "anchor" },
    { title: "Termine & Tickets", url: "#termine", type: "anchor" },
  ]

  let InfoCategories = []
  for (let i = 0; i < content.category?.length; i++) {
    if (i > 0) {
      InfoCategories.push(", ")
    }
    InfoCategories.push(
      storyblokDatasourceLookup("categories", content.category[i])
    )
  }

  let InfoDescription = []
  for (let i = 0; i < content.infos?.length; i++) {
    InfoDescription.push(i > 0 ? ", " : "")
    InfoDescription.push(
      content.infos[i].indexOf("fsk") !== -1 ? (
        <a
          key={i}
          className="font-semibold hover:text-primary-hover"
          href="https://www.fsk.de/?seitid=508&tid=72"
          target="_blank"
        >
          Altersfreigabe:
        </a>
      ) : (
        ""
      )
    )
    InfoDescription.push(
      " " + storyblokDatasourceLookup("infos", content.infos[i])
    )
  }

  const { Modal, setShowModal } = useModal()

  const dynamicFormbuilderUrl = function (datetime, title) {
    return (
      '<script type="text/javascript" defer src="https://form-eu.123formbuilder.com/embed/61076.js" data-role="form" data-default-width="650px" data-embed-type="lightbox-text-link btn btn-primary" data-custom-vars="control2420701=' +
      encodeURIComponent(title) +
      "&control2420702=" +
      datetime +
      '" data-embed-text-link="Reservieren"></script>'
    )
    // return (
    //   '<script type="text/javascript" defer src="https://form.123formbuilder.com/embed/6038198.js" data-custom-vars="control99525410=' +
    //   encodeURIComponent(title) +
    //   "&control99525414=" +
    //   datetime +
    //   '" data-role="form" data-default-width="650px"></script>'
    // )
  }
  const specialFormbuilderUrl = function (datetime, title) {
    return (
      '<script type="text/javascript" defer src="https://form-eu.123formbuilder.com/embed/61074.js" data-role="form" data-default-width="650px" data-embed-type="lightbox-text-link btn btn-primary" data-custom-vars="control2420649=' +
      encodeURIComponent(title) +
      "&control2420650=" +
      datetime +
      '" data-embed-text-link="Reservieren"></script>'
    )

    // return (
    //   '<script type="text/javascript" defer src="https://form.123formbuilder.com/embed/6070873.js" data-custom-vars="control100135187=' +
    //   encodeURIComponent(title) +
    //   "&control100135188=" +
    //   datetime +
    //   '" data-role="form" data-default-width="650px"></script>'
    // )
  }

  useEffect(() => {
    setTimeout(() => {
      let iframeSpinner = document.getElementById("iFrameSpinner")
      if (iframeSpinner) {
        iframeSpinner.style.display = "none"
      }
    }, 3000)
  }, [])

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={content.venue}
      location={location}
    >
      <SEO title={name}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      {content.image?.filename?.length > 0 && (
        <HeroBlock
          block={{
            component: "hero_block",
            venue: content.venue,
            image: content.image,
            flip_image: content.flip_image,
            headline: content.title,
            subheadline: content.subheadline,
            kicker: content.kicker,
          }}
        />
      )}

      <BlockWrapper
        blockPadding="pt-0 pb-block"
        blockWidth="content"
        block={{ _uid: "informationen" }}
      >
        <div className="flex flex-row flex-wrap -mx-grid">
          <div className="w-full px-grid">
            <div className="mt-4">
              {content.kicker && (
                <span className="kicker-h1">{content.kicker}</span>
              )}
              {content.title && (
                <h1 className="headline-h1">{content.title}</h1>
              )}
              {content.subheadline && (
                <span className=" subheadline-h1">{content.subheadline}</span>
              )}
            </div>
            <div className="mt-4">
              {content.duration?.length > 0 && (
                <span className="inline-block mr-6 capitalize">
                  <FontAwesomeIcon
                    icon={faClock}
                    size="1x"
                    className="mr-2 text-gray-600"
                  />
                  {content.duration}
                </span>
              )}
              {content.venue?.length > 0 && (
                <span className="inline-block mr-6 capitalize">
                  <FontAwesomeIcon
                    icon={faMapPin}
                    size="1x"
                    className="mr-2 text-gray-600"
                  />
                  {storyblokDatasourceLookup("venues", content.venue)}
                </span>
              )}
              {InfoCategories && InfoCategories.length > 0 && (
                <span className="inline-block mr-6 capitalize">
                  <FontAwesomeIcon
                    icon={faTag}
                    size="1x"
                    className="mr-2 text-gray-600"
                  />
                  {InfoCategories}
                </span>
              )}
              {InfoDescription && InfoDescription.length > 0 && (
                <span className="inline-block mr-6 capitalize">
                  <FontAwesomeIcon
                    icon={faInfoSquare}
                    size="1x"
                    className="mr-2 text-gray-600"
                  />
                  {InfoDescription}
                </span>
              )}
            </div>
            {content.intro?.length > 0 && (
              <div className="mt-8 mb-4 font-bold ">{content.intro}</div>
            )}
          </div>
          {content.cover?.filename?.length > 0 && (
            <div className="w-full mt-4 md:w-1/3 px-grid">
              <Image className="w-full" image={content.cover} />
            </div>
          )}
          <div
            className={`w-full mt-4 ${
              content?.cover?.filename?.length > 0 ? "md:w-2/3" : ""
            } px-grid`}
          >
            {content.description?.length > 0 && (
              <Richtext text={content.description} />
            )}
          </div>
        </div>
      </BlockWrapper>

      {children}

      <BlockWrapper
        block={{ headline: "Termine & Tickets", _uid: "termine" }}
        blockPadding="py-block"
        blockWidth="content"
        centerHeadline="true"
        showHeadline="true"
      >
        {content.price?.length > 0 && (
          <Richtext className="py-8" text={`**Preise:** ${content.price}`} />
        )}

        {content.dates?.map((date, index) => (
          <Fragment key={index}>
            {date && (
              <div className="w-full mx-auto">
                <div className="flex flex-row w-full pt-4 pb-4">
                  <div className="w-4/12">
                    <span className="text-xs leading-loose text-gray-600">
                      {moment(date.datetime).format("dddd")}
                      {date.note && " (" + date.note + ")"}
                    </span>
                  </div>
                  <div className="w-4/12">
                    <span className="mr-1 font-bold leading-normal">
                      {moment(date.datetime).format("DD.")}
                    </span>
                    <span>{moment(date.datetime).format("MMM YYYY")}</span>
                    <span className="block mt-1 text-xs leading-normal text-gray-600">
                      {moment(date.datetime).format("HH:mm") !== "00:00" && (
                        <>{moment(date.datetime).format("HH:mm")} Uhr</>
                      )}
                    </span>
                  </div>

                  <div className="w-4/12 -mt-4">
                    <ICalendarLink
                      className="mt-4 mr-4 btn btn-secondary tooltip"
                      event={{
                        title: content.title,
                        description: `Weitere Informationen: ${location?.href}`,
                        startTime: date.datetime,
                        endTime: "",
                        location: storyblokDatasourceLookup(
                          "venues",
                          content.venue
                        ),
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarPen}
                        fixedWidth
                        size="1x"
                        className="inline-block"
                      />
                      <div className="tooltip-text">
                        Termin in Kalender übernehmen.
                      </div>
                    </ICalendarLink>
                    {(date.ticket_link !== "" ||
                      date.cinetixx_link !== "" ||
                      date.formular_link?.cached_url?.length > 0 ||
                      date.dynamic_formular !== "") && (
                      <button
                        onClick={() => {
                          setActiveItem(date)
                          setShowModal(true)
                        }}
                        className="mt-4 btn btn-secondary"
                      >
                        Tickets
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ))}
        <Modal>
          <div className="flex flex-row flex-wrap">
            {content.venue !== "kino-alte-muehle" && (
              <div className="w-full lg:w-1/2">
                <div className="prose">
                  <b>Kartenbüro Reservierung</b>
                  <br />
                  Klaus-Havenstein-Weg 1<br />
                  61118 Bad Vilbel
                  <br />
                  Tel: <a href="tel:06101559455">06101 / 55 94 55</a>
                  <br />
                  Mail:{" "}
                  <Link link="tickets@bad-vilbel.de">
                    tickets@bad-vilbel.de
                  </Link>
                </div>
              </div>
            )}
            <div
              className={`w-full ${
                content.venue !== "kino-alte-muehle" ? "lg:w-1/2" : ""
              }`}
            >
              {activeItem.dynamic_formular === "dynamic" && (
                <>
                  <WidgetEmbed
                    html={dynamicFormbuilderUrl(
                      moment(activeItem.datetime).format("DD.MM.YYYY HH:mm"),
                      content.title
                    )}
                  />
                  <div id="iFrameSpinner" className="text-center">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="3x"
                      className="mr-2 text-gray-600 spinner"
                    />
                  </div>
                </>
              )}
              {activeItem.dynamic_formular === "special" && (
                <>
                  <WidgetEmbed
                    html={specialFormbuilderUrl(
                      moment(activeItem.datetime).format("DD.MM.YYYY HH:mm"),
                      content.title
                    )}
                  />
                  <div id="iFrameSpinner">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size="3x"
                      className="mr-2 text-gray-600 spinner"
                    />
                  </div>
                </>
              )}
              {!activeItem.dynamic_formular &&
              activeItem.formular_link &&
              typeof activeItem.formular_link === "object" &&
              (activeItem.formular_link.url?.length > 0 ||
                activeItem.formular_link.cached_url?.length > 0) ? (
                <>
                  <div className="mb-2">
                    <b>Ticketreservierung</b>
                  </div>
                  {activeItem.price?.length > 0 && (
                    <Richtext
                      className="pb-4"
                      text={`**Preise:** ${activeItem.price}`}
                    />
                  )}
                  <Link
                    className="btn btn-primary"
                    link={activeItem.formular_link}
                    externalIcon={true}
                  >
                    Zum Formular
                  </Link>
                </>
              ) : !activeItem.dynamic_formular &&
                activeItem.cinetixx_link?.length > 0 ? (
                <IframeResizer
                  heightCalculationMethod="lowestElement"
                  log
                  src={activeItem.cinetixx_link}
                  style={{ width: "1px", minWidth: "100%" }}
                />
              ) : !activeItem.dynamic_formular &&
                activeItem.ticket_link?.length > 0 ? (
                <>
                  <div className="mb-2">
                    <b>Ticketbestellung</b>
                  </div>
                  {activeItem.price?.length > 0 && (
                    <Richtext
                      className="pb-4"
                      text={`**Preise:** ${activeItem.price}`}
                    />
                  )}
                  <Link
                    className="btn btn-primary"
                    link={activeItem.ticket_link}
                    externalIcon={true}
                  >
                    Zu Frankfurtticket
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal>
      </BlockWrapper>
    </Layout>
  )
}

export default EventPage
