import PageTypeNotFound from "./PageTypeNotFound"

import StandardPage from "./StandardPage"
import EventPage from "./EventPage"
import PersonPage from "./PersonPage"
import HeroPage from "./HeroPage"
import StartPage from "./StartPage"
import SchedulePage from "./SchedulePage"
import EventArchivePage from './EventArchivePage';

const PageTypesList = {
  standard_page: StandardPage,
  event_page: EventPage,
  person_page: PersonPage,
  hero_page: HeroPage,
  start_page: StartPage,
  schedule_page: SchedulePage,
  event_archive_page: EventArchivePage
}

const PageTypes = (type) => {
  if (typeof PageTypesList[type] === "undefined") {
    return PageTypeNotFound
  }
  return PageTypesList[type]
}

export default PageTypes
