// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   homepage -> boolean / Kultur in Bad Vilbel Startseite
//   highlight -> boolean / Spielort Startseite
//   venue -> option / Spielort
//   headline -> text
//   body -> bloks / Inhaltsblöcke
//   metadata -> custom
//   tab-7e279674-e08b-452b-837c-bc4204b1967e -> tab / SEO
//   cover -> asset / Teaser-Bild
// end of automatic

import React from "react"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import { Helmet } from "react-helmet"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"

import ThemeLogo from "../../components/modules/ThemeLogo"

import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import menuItems from "../../components/constants/menuItems"

const StandardPage = ({ content, name, children, location }) => {
  // TODO: submenu auslagern
  let submenuItems = {}
  const contentElementsWithHeadline = content.body?.filter(
    (el) => el.headline && el.kicker
  )
  const linksToContentElementsWithHeadline = contentElementsWithHeadline?.map(
    (el) => ({ title: el.kicker, url: `#${el._uid}`, type: "anchor" })
  )
  submenuItems.headline = content.headline
  submenuItems.links = linksToContentElementsWithHeadline

  const [themeLogo] = ThemeLogo(content.venue)

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={content.venue}
      location={location}
    >
      <SEO title={name}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      <div
        className={`pb-8 ${
          content.headline?.length > 0 ? "lg:pb-0" : "lg:pb-32"
        }`}
      >
        <div className="absolute top-0 right-0 z-50 w-1/2 h-auto px-8 pt-4 ml-auto opacity-100 lg:w-72">
          {themeLogo && (
            <div className="relative w-full pb-24 ml-auto sm:w-48 lg:w-56 lg:pb-32">
              <div className="absolute top-0 right-0 w-auto h-full">
                <Image
                  image={themeLogo}
                  className={`object-contain object-right-top h-full w-full`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {content.headline?.length > 0 && (
        <BlockWrapper block={{}} blockPadding="pb-0 pt-16 lg:pb-16 lg:pt-10">
          <div className="flex flex-row flex-wrap justify-between">
            <h1 className="inline-block text-2xl font-bold leading-none md:text-3xl md:leading-none font-display">
              {content.headline}
            </h1>
          </div>
        </BlockWrapper>
      )}

      {children}
    </Layout>
  )
}

export default StandardPage
