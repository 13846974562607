// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   venue -> option / Spielort
//   body -> bloks
// end of automatic

import React from "react"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import { Helmet } from "react-helmet"
import MasonryGridBlock from "../../components/blocks/MasonryGridBlock"
import BodyBlocks from "gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import VenuesGridBlock from "../../components/blocks/VenuesGridBlock"
import menuItems from "../../components/constants/menuItems"

const StartPage = ({ content, name, highlights, events, venue, location }) => {
  let submenuItems = menuItems
  submenuItems.headline = content.name
  //submenuItems.links = menuItems.links
  submenuItems.cta_title = "Veranstaltungskalender"

  //let menuItems = {}

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={{}}
      venue={content.venue}
      location={location}
    >
      <SEO title={name}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      {highlights?.length > 0 && (
        <MasonryGridBlock
          block={{
            component: "masonry_grid_block",
            cards: highlights,
            headline: "News",
          }}
        />
      )}
      {events?.length > 0 && (
        <MasonryGridBlock
          block={{
            component: "masonry_grid_block",
            cards: events,
            headline: "Programm",
          }}
        />
      )}

      <VenuesGridBlock></VenuesGridBlock>

      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default StartPage
