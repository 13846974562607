// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   image -> asset / Hero-Bild
//   flip_image -> boolean / Bild spiegeln
//   headline -> text / Überschrift
//   venue -> option / Spielort
//   hide_news -> boolean / News ausblenden
//   hide_schedule -> boolean / Programm ausblenden
//   hide_archive -> boolean / Archiv ausblenden
//   body -> bloks
//   tab-1b15cfe7-043c-416e-9975-84d4edbe9b69 -> tab / SEO
//   metadata -> custom
// end of automatic

import React from "react"
import SEO from "gatsby-theme-yum-components/src/components/modules/Seo"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import HeroBlock from "gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import { Helmet } from "react-helmet"
import MasonryGridBlock from "../../components/blocks/MasonryGridBlock"
import BodyBlocks from "gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import menuItems from "../../components/constants/menuItems"
import Button from "gatsby-theme-yum-components/src/components/atoms/Button"

const HeroPage = ({ content, name, highlights, events, venue, location }) => {
  let submenuItems = {}
  let linkToMasonry = []

  if (content.hide_news !== true) {
    linkToMasonry = linkToMasonry.concat({
      title: "News",
      url: "#news",
      type: "anchor",
    })
  }
  if (content.hide_schedule !== true) {
    linkToMasonry = linkToMasonry.concat({
      title: "Programm",
      url: "#programm",
      type: "anchor",
    })
  }

  const contentElementsWithHeadline = content.body?.filter((el) => el.kicker)
  const linksToContentElementsWithHeadline = contentElementsWithHeadline?.map(
    (el) => ({ title: el.kicker, url: `#${el._uid}`, type: "anchor" })
  )
  submenuItems.headline = name
  submenuItems.links = linkToMasonry.concat(linksToContentElementsWithHeadline)

  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={content.venue}
      location={location}
    >
      <SEO title={name}></SEO>
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      <HeroBlock
        block={{
          venue: content.venue,
          component: "hero_block",
          image: content.image,
          flip_image: content.flip_image,
          headline: content.headline,
        }}
      />

      {content.hide_news !== true && highlights && highlights.length > 0 && (
        <MasonryGridBlock
          block={{
            _uid: "news",
            component: "masonry_grid_block",
            cards: highlights,
            headline: "News",
          }}
        />
      )}
      {content.hide_schedule !== true && events && events.length > 0 && (
        <MasonryGridBlock
          block={{
            _uid: "programm",
            component: "masonry_grid_block",
            cards: events,
            headline: "Programm",
          }}
        />
      )}
      {(content.hide_schedule !== true || content.hide_archive !== true) && (
        <BlockWrapper
          block={{}}
          blockPadding={
            highlights?.length > 0 || events?.length > 0 ? "pb-16" : "py-block"
          }
        >
          {content.hide_schedule !== true && (
            <Button className="mr-4" link="schedule">
              Veranstaltungskalender
            </Button>
          )}
          {content.hide_archive !== true && (
            <Button btnType="secondary" link="archiv">
              Archiv
            </Button>
          )}
        </BlockWrapper>
      )}
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default HeroPage
